import Vue from 'vue'
import Vuex from 'vuex'
import 'current-device'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // targetFolder: 'desktop',
    // targetFolder: window.device.type === 'desktop' || window.device.type === 'tablet' ? 'desktop' : 'mobile',
    targetFolder: window.device.type === 'desktop' ? 'desktop' : 'mobile',
    // isDesktop: true
    // isDesktop: window.device.type === 'desktop' || window.device.type === 'tablet'
    isDesktop: window.device.type === 'desktop'
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
