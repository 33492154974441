import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import 'normalize.css/normalize.css'

// import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true
// import './fullpage.scrollHorizontally.min' // Optional. When using fullpage extensions
import VueFullPage from 'vue-fullpage.js'
import { Button, Row, Col } from 'element-ui'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import 'swiper/swiper-bundle.css'
SwiperClass.use([Pagination, Mousewheel, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))
// const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)

Vue.use(Button)
Vue.use(Row)
Vue.use(Col)

Vue.use(VueFullPage)

Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false

// if (window.device.type !== 'desktop') {
// eslint-disable-next-line no-inner-declarations
function init () {
  // 获取屏幕宽度
  var width = document.documentElement.clientWidth
  // 设置根元素字体大小。此时为宽的10等分
  document.documentElement.style.fontSize = width / 10 + 'px'
}
init()
// 监听手机旋转的事件的时机，重新设置
window.addEventListener('orientationchange', init)
// 监听手机窗口变化，重新设置
window.addEventListener('resize', init)
// }
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
