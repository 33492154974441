<template>
  <div id="main-page">
    <div v-if="contactPanel" class="contact bg">
      <div class="scan-code">
        <div class="font">微信公众号</div>
        <div class="code">
          <img src="../assets/wechat_code.jpg" alt="">
        </div>
      </div>
      <div class="scan-code qq-group">
        <div class="font">新浪微博</div>
        <div class="code">
          <img src="../assets/weibo_code.png" alt="">
        </div>
      </div>
      <!-- <div class="scan-code qq-group">
        <div class="font">玩家Q Q群</div>
        <div class="code">
          <img src="../assets/qq_code.png" alt="">
        </div>
      </div> -->
    </div>
    <div v-if="isDesktop" class="main-nav">
      <img :src="require(`../assets/${targetFolder}/nav/top.png`)" class="top-img">
      <div class="dashed-line">
        <img :src="require(`../assets/${targetFolder}/nav/border_image.png`)" alt="">
      </div>
      <div
        v-for="(item,index) in navArray"
        :key="item"
        :class="{
          first: index === 0
        }"
        class="item" >
        <img
          class="go"
          @click="goAnchor(item)"
          :src="require(`../assets/${targetFolder}/nav/${item}${nowIndex + 1 === item ? '_active':''}.png`)">
        <img
          class="icon"
         :src="require(`../assets/${targetFolder}/nav/icon${nowIndex + 1 === item ? '_active':''}.png`)">
      </div>
    </div>
    <full-page ref="fullpage" :options="options.fullpage" id="fullpage">
        <div class="section page1 bg">
          <div class="top bg">
            <img :src="require(`../assets/${targetFolder}/1/logo.png`)" class="logo">
            <div class="action-container">
              <div v-if="isLogin" class="user-info">
                <img :src="require(`../assets/${targetFolder}/1/user.png`)">
                <span class="user-id">{{userInfo.user_name}}</span>
                <span class="logout" @click="logout">退出</span>
              </div>
              <!-- <img
                class="login-button"
                v-if="!isLogin"
                @click="showPanel('login')"
                :src="require(`../assets/${targetFolder}/1/login_button.png`)"> -->
              <img
                class="wechat-button"
                :src="require(`../assets/${targetFolder}/1/wechat_button.png`)"
                @click="contactPanel = !contactPanel">
              <img
                v-if="!isDesktop"
                :src="require(`../assets/${targetFolder}/1/nav_button.png`)"
                @click="showNavHandel">
            </div>
            <div class="mobile-nav" v-if="!isDesktop && showNav">
              <div class="highlight" @click="goAnchor('1')">首页</div>
              <!-- <div @click="goAnchor('2')">预约福利</div> -->
              <div @click="goAnchor('2')">新闻公告</div>
              <div @click="goAnchor('3')">人物介绍</div>
              <div @click="goAnchor('4')">游戏特色</div>
            </div>
          </div>
          <div class="content">
            <div class="slogan">
              <img :src="require(`../assets/${targetFolder}/1/slogan.png`)" class="slogan">
              <!-- <img :src="gifSrc" class="slogan"> -->
              <template  v-if="true" >
                <!-- <img :src="require(`../assets/${targetFolder}/1/play_button_bg.png`)" class="play"> -->
                <img @click="showVideo" :src="require(`../assets/${targetFolder}/1/play_button.gif`)" class="play">
                <!-- <img @click="showVideo" :src="require(`../assets/${targetFolder}/1/play_button_top.png`)" class="play"> -->
              </template>

            </div>
            <div class="yuyue">
              <template v-if="!isDesktop">
                <div class="icon" v-if="isDesktop">
                  <img :src="require(`../assets/${targetFolder}/1/download_icon.png`)">
                </div>
                <div class="download">
                  <img :src="require(`../assets/${targetFolder}/1/download.gif`)">
                  <div class="mask" @click="showPanel"></div>
                </div>
                <div class="code" v-if="isDesktop">
                  <img style="border-radius: 15px;" :src="require(`../assets/${targetFolder}/1/download_code_2022_03_03.png`)">
                </div>
                <div class="tip_12 bg" @click="showTip = true"></div>
              </template>
              <template v-else>
                <div class="icon" v-if="isDesktop">
                  <img style="border-radius: 15px;" :src="require(`../assets/${targetFolder}/1/download_code_2022_03_03.png`)">
                </div>
                <div class="download">
                  <img @click="downloadPc" :src="require(`../assets/${targetFolder}/1/download_pc.png`)">
                </div>
                <div class="code" v-if="isDesktop">
                  <img @click="downloadIos" :src="require(`../assets/${targetFolder}/1/download_ios.png`)">
                  <img @click="downloadAnd" :src="require(`../assets/${targetFolder}/1/download_and.png`)">
                </div>
                <div class="tip_12 bg" @click="showTip = true"></div>
              </template>
            </div>
          </div>
        </div>
        <!-- <div class="section page2 bg">
            <div class="title">
              <img :src="require(`../assets/${targetFolder}/2/title.png`)">
            </div>
            <div class="content">
              <div class="progress-bar">
                <img :src="require(`../assets/${targetFolder}/2/bar_${bonusArray.filter(v => v.complete).length}.png`)">
              </div>
              <div class="bonus bg">
                <div
                  :class="'row ' + 'row-' + (index + 1)"
                  v-for="(item,index) of bonusArray"
                  :key="index">
                  <img
                    width="100%"
                    :src="require(`../assets/${targetFolder}/2/bonus_${index + 1}${item.complete ? '_complete' : ''}.png`)"
                    class="content">
                  <img
                    v-if="item.complete"
                    :src="require(`../assets/${targetFolder}/2/complete.png`)"
                    class="complete-icon">
                </div>
              </div>
            </div>
        </div> -->
        <div class="section page3 bg">
          <!-- <div class="center-container"> -->
            <div class="title">
              <img :src="require(`../assets/${targetFolder}/3/title.png`)">
            </div>
            <div class="content bg">
              <div class="line" v-if="!isDesktop"/>
              <div class="carousel-container bg">
                <div class="carousel" @click="carouselClick">
                  <swiper
                    ref="carousel"
                    style="height:100%;width:100%"
                    :options="options.carousel">
                    <swiper-slide v-for="item in slides" :key="item.src">
                      <img
                        style="width:100%;height:100%"
                        :data-id="item.artId"
                        :src="require(`../assets/swiper/${item.src}`)">
                    </swiper-slide>
                    <div
                      class="swiper-pagination carousel-pagination"
                      slot="pagination"></div>
                  </swiper>
                  <img class="tape_top" :src="require(`../assets/${targetFolder}/3/tape_top.png`)" alt="">
                  <img class="tape_bottom" :src="require(`../assets/${targetFolder}/3/tape_bottom.png`)" alt="">
                </div>
              </div>
              <div class="news-container">
                <div class="category">
                  <span
                    v-for="item in articleCategory"
                    :class="{
                      active: activeArticleCategory === item.value
                    }"
                    :key="item.value"
                    @click="changeArticleCategory(item.value)"
                  >{{item.label}}</span>
                </div>
                <div
                  v-if="topNews"
                  @click="$router.push(`news/${activeArticleCategory}/${topNews.article_id}`)"
                  class="top-news" :title="topNews.title">
                  {{topNews.title}}
                </div>
                <div class="article-list">
                  <div
                    class="row dashed"
                    v-for="(item) in articleList"
                    :key="item.id">
                    <span :title="item.title" @click="$router.push(`news/${activeArticleCategory}/${item.id}`)" class="title">【{{ item.cate_name }}】{{ item.title }}</span>
                    <span class="date">{{ parseTime(item.add_time + '000','{m}.{d}') }}</span>
                  </div>
                </div>
                <div class="more" @click="changeArticleCategory('more')">
                  <span>
                    查看全部资讯
                  </span>
                </div>
                <!-- <div class="more" @click="changeArticleCategory('more')">
                  <img src="../assets/more.jpg" class="more-img">
                  <span>
                    查看全部资讯
                  </span>
                </div> -->
              </div>
              <img class="ruler" :src="require(`../assets/${targetFolder}/3/ruler.png`)" alt="">
              <img v-if="isDesktop" class="radio" :src="require(`../assets/${targetFolder}/3/radio.png`)" alt="">
            </div>
          <!-- </div> -->
        </div>
        <div class="section page4 bg ">
          <div class="title">
            <img :src="require(`../assets/${targetFolder}/4/title.png`)">
          </div>
          <div class="content">
            <div class="view">
              <swiper ref="role" style="height:100%" :options="options.role">
                  <swiper-slide
                    class="swiper-no-swiping"
                    v-for="item in roles"
                    :key="item">
                    <img :src="require(`../assets/${targetFolder}/4/${item}.png`)">
                  </swiper-slide>
              </swiper>
            </div>
            <div class="list">
              <div v-if="isDesktop" class="row1">
                <img
                  v-for="item in roles.slice(0,4)"
                  :key="item"
                  @click="changeRole(item)"
                  :src="require(`../assets/${targetFolder}/4/${item}_button${item === activeRoleIndex?'_active':''}.png`)">
              </div>
              <div v-if="isDesktop" class="row2" >
                <img
                  v-for="item in roles.slice(4)"
                  :key="item"
                  @click="changeRole(item)"
                  :src="require(`../assets/${targetFolder}/4/${item}_button${item === activeRoleIndex?'_active':''}.png`)">
              </div>
              <div v-if="!isDesktop" class="row1">
                <img
                  v-for="item in roles.slice(0,2)"
                  :key="item"
                  @click="changeRole(item)"
                  :src="require(`../assets/${targetFolder}/4/${item}_button${item === activeRoleIndex?'_active':''}.png`)">
              </div>
              <div v-if="!isDesktop" class="row2" >
                <img
                  v-for="item in roles.slice(2,4)"
                  :key="item"
                  @click="changeRole(item)"
                  :src="require(`../assets/${targetFolder}/4/${item}_button${item === activeRoleIndex?'_active':''}.png`)">
              </div>
              <div v-if="!isDesktop" class="row3">
                <img
                  v-for="item in roles.slice(4,6)"
                  :key="item"
                  @click="changeRole(item)"
                  :src="require(`../assets/${targetFolder}/4/${item}_button${item === activeRoleIndex?'_active':''}.png`)">
              </div>
              <div v-if="!isDesktop" class="row4" >
                <img
                  v-for="item in roles.slice(6)"
                  :key="item"
                  @click="changeRole(item)"
                  :src="require(`../assets/${targetFolder}/4/${item}_button${item === activeRoleIndex?'_active':''}.png`)">
              </div>
            </div>
            <div class="nav">
              <img @click="roleGoPre" class="pre" :src="require(`../assets/${targetFolder}/4/pre.png`)">
              <img @click="roleGoNext" class="next" :src="require(`../assets/${targetFolder}/4/next.png`)">
            </div>
          </div>
          <img v-if="isDesktop" class="ball" :src="require(`../assets/${targetFolder}/4/ball.png`)">
          <img v-if="isDesktop" class="woman" :src="require(`../assets/${targetFolder}/4/woman.png`)">
        </div>
        <div class="section page5 bg">
          <div class="title">
            <img :src="require(`../assets/${targetFolder}/5/title.png`)">
          </div>
          <div class="game-carousel">
            <!-- <img src="../assets/desktop/5/swiper_bg.png" alt=""> -->
            <div class="game-box">
              <swiper ref="game" style="width:100%" :options="options.game">
                <swiper-slide
                  class="swiper-no-swiping"
                  v-for="item in gameSlides"
                  :key="item">
                  <img style="width:100%" :src="require(`../assets/swiper/${item}`)">
                </swiper-slide>
              </swiper>
            </div>
            <img @click="gamePre" :src="require(`../assets/${targetFolder}/5/pre.png`)" class="pre">
            <img @click="gameNext" :src="require(`../assets/${targetFolder}/5/next.png`)" class="next">
          </div>
          <div class="message" v-if="isDesktop">
            <p class="margin">健康游戏忠告</p>
            <p>抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。</p>
            <p>Copyright © 2014-2020 Shanghai Gume Technology Co.,Ltd All Rights Reserved. 上海咕么信息科技有限公司 版权所有</p>
            <p>ICP备案号：沪ICP备16011695号-1 沪网文（2016）2295-133号</p>
            <!-- TODO -->
            <p>
              <span>开发者 上海咕么信息科技有限公司</span>
              <span>版本号 1.1.4 </span>
              <span>更新日期 2021.4.8</span>
            </p>
            <p>玩家互动信息技术（海南）有限公司</p>
            <p>
              <span class="under-line" @click="open('./privacy.html')">隐私协议</span>
              <span class="under-line" @click="open('./permissions.html')">用户权限</span>
            </p>
          </div>
          <div class="message" v-if="!isDesktop">
            <p class="margin">健康游戏忠告</p>
            <p>抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。</p>
            <p>适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。</p>
            <p>Copyright © 2014-2020 Shanghai Gume Technology Co.,Ltd All Rights Reserved.</p>
            <p>上海咕么信息科技有限公司 版权所有</p>
            <p>ICP备案号：沪ICP备16011695号-1 沪网文（2016）2295-133号</p>
            <!-- TODO -->
            <p>开发者 上海咕么信息科技有限公司</p>
            <p><span>版本号 1.1.4</span><span>更新日期 2021.4.8</span></p>
            <p>玩家互动信息技术（海南）有限公司</p>
            <p>
              <span class="under-line" @click="open('./privacy.html')">隐私协议</span>
              <span class="under-line" @click="open('./permissions.html')">用户权限</span>
            </p>
          </div>
        </div>
    </full-page>
    <Dialog ref="dialog" @update-status="updateStatus" />
    <div class="tip-panel" v-if="showTip" @click="showTip = false">
      <div class="content bg"  @click.stop>
        <div class="title">
        《全民学霸》适龄提示
        </div>
        <div class="text_row">
          1.本游戏是一款模拟经营养成游戏，适用于年满12周岁及以上的用户，建议未成年人在家长监护下使用游戏产品。
        </div>
        <div class="text_row">
        2.本游戏通过游戏界面的交互、角色收集、关卡对战等玩法推进剧情。游戏中有少量基于文字的陌生人社交系统。
        </div>
        <div class="text_row">
        3.本游戏中有用户实名认证系统，认证为未成年人的用户将接受以下管理：
        游戏中有部分的付费道具。未满8周岁的用户不能付费；8周岁以上未满16周岁的未成年人用户，单次充值金额不得超过50元人民币，每月充值金额累计不得超过200元人民币；16周岁以上的未成年人用户，单次充值金额不得超过100元人民币，每月充值金额累计不得超过400元人民币。
        未成年人用户仅可在周五、周六、周日和法定节假日的20时至21时，进行1小时的游戏体验。
        </div>
        <div class="text_row">
        4.本游戏以收集养成为主。游戏以校园经营为蓝图，画风卡通怀旧，带玩家体验青春校园的时光，可以给玩家带来舒适愉悦的正面情绪。
        </div>
      </div>
    </div>
    <video-viewr ref="video" />
  </div>
</template>

<script>
import Dialog from './Dialog.vue'
import VideoViewr from './Video_1.vue'
import { getUserInfo, setUserInfo, removeUserInfo } from '@/utils/store'
import {
  getProcess,
  getArticleCategory,
  getArticleList,
  getInfo,
  logout
} from '@/api'
import { parseTime } from '@/utils'
export default {
  name: 'Home',
  components: {
    Dialog,
    VideoViewr
  },
  data () {
    const self = this
    const userInfo = getUserInfo()
    if (userInfo) {
      this.getUserInfo(userInfo.token)
    }
    return {
      gifSrc: '',
      gameSlides: ['5-1.jpg', '5-2.jpg', '5-3.jpg', '5-4.jpg', '5-5.jpg'],
      slides: [
        {
          src: '3-1.jpg',
          artId: 9077
        }, {
          src: '3-2.jpg',
          artId: 9078
        }
      ],
      page: {
        current: 1,
        size: 5
      },
      topNews: {
        article_id: '1',
        title: '置顶新闻'
      },
      userInfo,
      contactPanel: false,
      showNav: false,
      showTip: false,
      device: window.device,
      nowIndex: 0,
      activeRoleIndex: '1',
      carouselActiveIndex: 1,
      activeArticleCategory: '1',
      articleCategory: [
        {
          label: '最新',
          value: '1'
        }, {
          label: '新闻',
          value: '2'
        }, {
          label: '公告',
          value: '3'
        }, {
          label: '活动',
          value: '4'
        }, {
          label: '更多',
          value: 'more'
        }
      ],
      articleList: [
      ],
      roles: [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8'
      ],
      navArray: [
        1,
        2,
        3,
        4
      ],
      bonusArray: [
        {
          complete: true
        },
        {
          complete: true
        },
        {
          complete: true
        },
        {
          complete: true
        }
      ],
      options: {
        game: {
          loop: true,
          noSwiping: true,
          autoplay: {
            disableOnInteraction: false,
            delay: 5000
          }
        },
        role: {
          loop: true,
          noSwiping: true,
          on: {
            slideChange: function () {
              // alert('改变了，activeIndex为' + this.activeIndex)
              let i = this.activeIndex
              if (i === 0) {
                i = 8
              }
              if (i === 9) {
                i = 1
              }
              self.activeRoleIndex = i + ''
            }
          }
        },
        carousel: {
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          autoplay: {
            disableOnInteraction: false,
            delay: 5000
          },
          loop: true
        },
        fullpage: {
          // menu: '#menu',
          // lockAnchors: false,
          // navigation: false,
          // navigationPosition: 'right',
          // showActiveTooltip: false,
          // slidesNavigation: false,
          // slidesNavPosition: 'bottom',
          onLeave (index, nextIndex) {
          // console.log(index.index)
          // console.log(self)
          // console.log(arr)
            self.contactPanel = false
            self.showTip = false
            self.nowIndex = nextIndex.index
          }
        }
      }
    }
  },
  watch: {
    showNav (v) {
      if (v) {
        this.contactPanel = false
      }
    },
    contactPanel (v) {
      if (v) {
        this.showNav = false
      }
    }
  },
  created () {
    require(`../styles/${this.targetFolder}/index.scss`)
    // require(`../styles/${this.targetFolder}/index.scss`)
  },
  computed: {
    isLogin () {
      return !!this.userInfo
    },
    activeRole () {
      return require(`../assets/${this.targetFolder}/4/${this.activeRoleIndex}.png`)
    },
    roleSwiper () {
      return this.$refs.role.$swiper
    },
    gameSwiper () {
      return this.$refs.game.$swiper
    },
    targetFolder () {
      return this.$store.state.targetFolder
    },
    isDesktop () {
      return this.$store.state.isDesktop
    }
  },
  mounted () {
    if (this.$route.query.anchor) {
      this.goAnchor(this.$route.query.anchor)
    }
    this.init()
    // setTimeout(() => {
    //   this.slides = [1, 2, 3, 4, 5]
    // }, 2000)
  },
  methods: {
    init () {
      // this.getProcess()
      this.getArticleCategory()
    },
    parseTime,
    open (url) {
      window.open(url)
    },
    carouselClick (e) {
      const target = e.target
      console.log(target.nodeName)
      if (target.nodeName === 'IMG') {
        this.$router.push('news/newest/' + target.getAttribute('data-id'))
      }
    },
    updateStatus () {
      const r = getUserInfo()
      if (r) {
        this.userInfo = r
      } else {
        this.userInfo = null
      }
      // this.userInfo = getUserInfo()
    },
    showVideo () {
      this.$refs.video.doShow()
    },
    async getProcess () {
      try {
        let { data } = await getProcess()
        this.bonusArray.forEach(v => { v.complete = false })
        data = 1000000
        if (!Array.isArray(data) && data >= 100000) {
          this.bonusArray[0].complete = true
          if (data >= 250000) {
            this.bonusArray[1].complete = true
          }
          if (data >= 500000) {
            this.bonusArray[2].complete = true
          }
          if (data >= 1000000) {
            this.bonusArray[3].complete = true
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getArticleCategory () {
      try {
        const sort = ['公告', '活动', '新手攻略', '进阶攻略', '公益']
        const { data } = await getArticleCategory()
        this.articleCategory = []
        let temp = []
        sort.forEach(v => {
          const target = data.find(t => t.cate_name === v)
          if (target) {
            temp.push({
              label: target.cate_name,
              value: target.cate_id
            })
          }
        })
        temp = [...temp]
        temp.unshift({
          label: '最新',
          value: 'newest'
        })
        // temp.push({
        //   label: '更多',
        //   value: 'more'
        // })
        this.articleCategory = temp
        this.activeArticleCategory = temp[0].value
        this.getArticList()
      } catch (e) {
        console.log(e)
        alert(e.message)
      }
    },
    async logout () {
      // const r = awa
      try {
        logout()
      } catch (e) {

      }
      removeUserInfo()
      this.updateStatus()
    },
    async getUserInfo (token) {
      const { data } = await getInfo({ token })
      setUserInfo(data)
      this.updateStatus()
    },
    async getArticList () {
      try {
        const params = {
          cate_id: this.activeArticleCategory,
          limitsize: 4
        }
        if (this.activeArticleCategory === 'newest') {
          params.cate_id = undefined
        }
        const { data } = await getArticleList(params)
        // console.log(data.result)
        this.articleList = data.result
        this.topNews = data.topData[0]
        // console.log(data.topData)
      } catch (e) {
        console.log(e)
        // alert(e.message)
      }
    },
    downloadPc () {
      window._hmt.push(['_trackEvent', '游戏', 'click-PC-download'])
      window.open('https://cdn.school.unoao.com/school_online/pc/5064/install/qmxb_5064_1_0_1_x86_install.exe')
    },
    downloadIos () {
      window._hmt.push(['_trackEvent', '游戏', 'click-iOS-download'])
      window.open('https://apps.apple.com/cn/app/id1556383376')
    },
    downloadAnd () {
      window._hmt.push(['_trackEvent', '游戏', 'click-Android-download'])
      window.open('https://apk.gm88.com/dl/qmxb-4946-20220222/qmxb-4946-20220222.17.b70c2e8c87dbf7c90d996ead41ed00ba.apk')
    },
    showPanel () {
      // console.log(window._hmt)
      console.log(this.device.os)
      // macos
      if (this.device.os === 'ios' || this.device.os === 'macos') {
        window._hmt.push(['_trackEvent', '游戏', 'click-iOS-download'])
        window.open('https://apps.apple.com/cn/app/id1556383376')
      } else {
        window._hmt.push(['_trackEvent', '游戏', 'click-Android-download'])
        window.open('https://gdown.baidu.com/appcenter/pkg/upload/ef6c10aaacd66f275b6673ad3143bae9')
      }
    },
    showNavHandel () {
      this.showNav = !this.showNav
    },
    goAnchor (v) {
      // this.$router.query.anchor = v
      this.showNav = false
      this.contactPanel = false
      this.$refs.fullpage.api.moveTo(v)
    },
    changeArticleCategory (value) {
      if (value === 'more') {
        this.$router.push('/news/' + this.activeArticleCategory)
      } else {
        this.activeArticleCategory = value
        this.getArticList()
      }
    },
    changeRole (index) {
      console.log('埋点')
      window._hmt.push(['_trackEvent', '游戏', 'click_characters'])
      this.roleSwiper.slideTo(Number(index))
    },
    gamePre () {
      this.gameSwiper.slidePrev()
    },
    gameNext () {
      this.gameSwiper.slideNext()
    },
    roleGoPre () {
      console.log('埋点')
      window._hmt.push(['_trackEvent', '游戏', 'click_characters'])
      this.roleSwiper.slidePrev()
    },
    roleGoNext () {
      console.log('埋点')
      window._hmt.push(['_trackEvent', '游戏', 'click_characters'])
      this.roleSwiper.slideNext()
    }
  }

}
</script>
<style lang="scss">
  // @import '@/styles/index.scss';
</style>
