import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Index.vue'
import NewsLayout from '../views/NewsLayout.vue'
import News from '../views/News.vue'
import Details from '../views/Details.vue'
import NotAllow from '../views/NotAllow.vue'
Vue.use(VueRouter)

function isWechat () {
  // return true || /MicroMessenger/i.test(window.navigator.userAgent)
  return /MicroMessenger/i.test(window.navigator.userAgent)
}
function openAnthor () {
  return isWechat() && !(window.device.ios() || window.device.ipad() || window.device.iphone() || window.device.ipod())
}
// alert(openAnthor())
const routes = openAnthor() ? [{
  path: '*',
  name: '404',
  component: NotAllow
}] : [
  {
    path: '/',
    name: 'Index',
    component: Home
  }, {
    path: '/index',
    name: '/',
    redirect: '/'
  }, {
    path: '/news',
    component: NewsLayout,
    redirect: '/',
    children: [
      {
        path: ':type',
        name: 'cate',
        component: News
      }, {
        path: ':type/:id',
        name: 'details',
        component: Details
      }
    ]
  },
  {
    path: '*',
    name: '404',
    component: Home
  }
]

const router = new VueRouter({
  routes
})

export default router
