<template>
  <div id="news-layout" :class="{
    'hide-header': nativeQuery.header.indexOf('0') !== -1
  }">
    <div class="header bg">
      <div v-if="isDesktop">
        <span @click="go('1')">官网首页</span>
        <!-- <span @click="go('2')">预约福利</span> -->
        <span @click="$router.push('/news/newest')" class="highlight">新闻公告</span>
        <span @click="go('3')">人物介绍</span>
        <span @click="go('4')">游戏特色</span>
      </div>
      <img v-if="isDesktop" :src="require(`../assets/${targetFolder}/1/logo.png`)" class="logo">
      <div v-if="!isDesktop">
        <img @click="goBack" class="back" :src="require(`../assets/${targetFolder}/news/back.png`)">
        <img class="title" :src="require(`../assets/${targetFolder}/news/title.png`)">
      </div>
    </div>
    <div class="container">
      <div class="content">
        <keep-alive>
          <router-view/>
        </keep-alive>
      </div>
      <div class="message bg">
        <div v-if="isDesktop" class="box">
          <p class="margin">健康游戏忠告</p>
          <p>抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。</p>
          <p>Copyright © 2014-2020 Shanghai Gume Technology Co.,Ltd All Rights Reserved. 上海咕么信息科技有限公司 版权所有</p>
          <p>ICP备案号：沪ICP备16011695号-1 沪网文（2016）2295-133号</p>
        </div>
        <div v-if="!isDesktop" class="box">
          <p>健康游戏忠告</p>
          <p>抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。</p>
          <p>适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。</p>
          <p>Copyright © 2014-2020 Shanghai Gume Technology Co.,Ltd All Rights Reserved.</p>
          <p>上海咕么信息科技有限公司 版权所有</p>
          <p>ICP备案号：沪ICP备16011695号-1 沪网文（2016）2295-133号</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsLayout',
  data () {
    return {

    }
  },
  created () {
    require(`../styles/${this.targetFolder}/newsLayout.scss`)
  },
  mounted () {
    console.log(this.$route)
  },
  computed: {
    targetFolder () {
      return this.$store.state.targetFolder
    },
    isDesktop () {
      return this.$store.state.isDesktop
    },
    nativeQuery () {
      var resultObj = {}
      var search = window.location.search
      if (search && search.length > 1) {
        search = search.substring(1)
        var items = search.split('&')
        for (var index = 0; index < items.length; index++) {
          if (!items[index]) {
            continue
          }
          var kv = items[index].split('=')
          resultObj[kv[0]] = typeof kv[1] === 'undefined' ? '' : kv[1]
        }
      }
      if (resultObj.header === undefined) {
        resultObj.header = '1'
      }

      return resultObj
    }
  },
  methods: {
    goBack () {
      // console.log(this.$route.params)
      if (this.$route.params.id) {
        // if (history.length > 1) {
        history.back()
        // this.$router.go(-1)
        // } else {
        //   this.$router.push('/news/' + this.$route.params.type)
        // }
      } else {
        this.$router.push('/')
      }
    },
    go (v) {
      this.$router.push({
        path: '/',
        query: {
          anchor: v
        }
      })
    }
  }
}
</script>

<style>

</style>
