<template>
  <div
    :style="{
      display: pool.length === 0 ? 'none' : 'block'
    }"
    id="dialog">
    <div class="out-border">
      <div class="dialog-container">
        <div @click="close(true)" class="close">
        </div>
        <div v-if="activePanel === 'yuyue'" class="yuyue">
          <div class="title-img">
            <img src="../assets/dialog/yuyue.png" >
          </div>
          <div class="device-type-container">
            <div>
              <img
                @click="form.deviceType = '1'"
                :src="require(`../assets/dialog/ios${form.deviceType === '1' ? '_checked' : ''}.png`)" >
            </div>
            <div>
              <img
                @click="form.deviceType = '0'"
                :src="require(`../assets/dialog/android${form.deviceType === '0' ? '_checked' : ''}.png`)" >
            </div>
          </div>
          <div class="form">
            <div class="phone">
              <input
                placeholder="请输入手机号码"
                type="text"
                v-model="form.phone"
                maxlength="11"
                class="phone">
            </div>
            <div class="code">
              <input
                placeholder="验证码"
                maxlength="8"
                v-model="form.code"
                type="text">
              <span :class="{disabled: timer}">
                <div v-if="timer" class="code-down">
                  请{{countDown}}s后重试
                </div>
                <img class="disabled" v-if="timer" @click="sendCode" src="../assets/dialog/code_bg.png">
                <img v-else @click="sendCode" src="../assets/dialog/get_code.png">
              </span>
            </div>
            <div class="yuyue">
              <img @click="doYuyue" src="../assets/dialog/yuyue_button.png" alt="">
            </div>
          </div>
        </div>
        <div v-else-if="activePanel === 'login'" class="login">
          <div class="title-img">
            <img src="../assets/dialog/login.png" >
          </div>
          <div class="form">
            <div class="phone">
              <input
                placeholder="请输入手机号码"
                v-model="form.phone"
                type="tel"
                maxlength="11"
                class="phone">
            </div>
            <div class="code">
              <input
                placeholder="验证码"
                maxlength="8"
                v-model="form.code"
                type="text">
              <span :class="{disabled: timer}">
                <div v-if="timer" class="code-down">
                  请{{countDown}}s后重试
                </div>
                <img class="disabled" v-if="timer" @click="sendCode" src="../assets/dialog/code_bg.png">
                <img v-else @click="sendCode" src="../assets/dialog/get_code.png">
              </span>
            </div>
            <div class="login">
              <img @click="login" src="../assets/dialog/login_buton.png" alt="">
            </div>
          </div>
        </div>
        <div v-else-if="activePanel === 'login-fail'" class="login-fail">
          <div class="fail-img">
            <img src="../assets/dialog/warring.png" alt="">
          </div>
          <div class="fail-text">
            账号/验证码错误！
          </div>
          <div class="fail-button">
            <img src="../assets/dialog/back.png" alt="">
          </div>
        </div>
        <div v-else-if="activePanel === 'code-fail'" class="code-fail">
          <div class="fail-img">
            <img src="../assets/dialog/alert.png" alt="">
          </div>
          <div class="fail-text">
            验证码错误，请重新输入！
          </div>
          <div class="fail-button">
            <img @click="close(false)" src="../assets/dialog/back.png" alt="">
          </div>
        </div>
        <div v-else-if="activePanel === 'yuyue-success'" class="yuyue-success">
          <div class="fail-img">
            <img src="../assets/dialog/success.png" alt="">
          </div>
          <div class="fail-text">
            恭喜您，预约成功！
          </div>
          <div class="fail-button">
            <img @click="successClick" src="../assets/dialog/confirm.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sendCaptcha, doLogin, doReservation } from '@/api'
import { setUserInfo } from '@/utils/store'
export default {
  name: 'Dialog',
  data () {
    return {
      pool: [],
      // pool: ['yuyue-success', 'code-fail', 'login', 'yuyue'],
      timer: null,
      countDown: 60,
      form: {
        deviceType: '',
        phone: '',
        code: ''
      }
    }
  },
  computed: {
    activePanel () {
      return this.pool[this.pool.length - 1]
    },
    targetFolder () {
      return this.$store.state.targetFolder
    },
    isDesktop () {
      return this.$store.state.isDesktop
    }
  },
  mounted () {
    this.countDown = 10
  },
  created () {
    require(`../styles/${this.targetFolder}/dialog.scss`)
  },
  methods: {
    doShow (panel) {
      this.pool.push(panel)
    },
    async sendCode () {
      if (this.timer) {
        return
      }
      if (!this.form.phone) {
        return alert('请输入手机号！')
      }
      if (!/^(?:(?:\+|00)86)?1\d{10}$/.test(this.form.phone)) {
        return alert('请输入正确的手机号！')
      }
      const body = {
        type: this.activePanel === 'login' ? 'login_code' : 'appointment_code',
        phone_mob: this.form.phone
      }
      try {
        const result = await sendCaptcha(body)
        console.log(result)
        alert('发送成功！')
        this.countDown = 60
        this.timer = setInterval(() => {
          this.countDown--
          if (this.countDown <= 0) {
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      } catch (e) {
        console.log(e)
        alert(e.message)
        const time = e.message.match(/\d+/g)
        if (time) {
          this.countDown = time[0]
          this.timer = setInterval(() => {
            this.countDown--
            if (this.countDown <= 0) {
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
        }
      }
    },
    async doYuyue () {
      if (!this.form.deviceType) {
        return alert('请选择系统！')
      }
      if (!this.form.phone) {
        return alert('请输入手机号！')
      }
      if (!/^(?:(?:\+|00)86)?1\d{10}$/.test(this.form.phone)) {
        return alert('请输入正确的手机号！')
      }
      if (!this.form.code) {
        return alert('请输入验证码！')
      }
      const body = {
        type: this.form.deviceType,
        phone_mob: this.form.phone,
        code: this.form.code
      }
      // console.log(body)

      try {
        await doReservation(body)
        this.pool.push('yuyue-success')
      } catch (e) {
        console.log(e)
        if (e.message === '验证码错误') {
          this.pool.push('code-fail')
        } else {
          alert(e.message)
        }
      }
    },
    successClick () {
      this.close(true)
      this.pool = []
    },
    async login () {
      if (!this.form.phone) {
        return alert('请输入手机号！')
      }
      if (!/^(?:(?:\+|00)86)?1\d{10}$/.test(this.form.phone)) {
        return alert('请输入正确的手机号！')
      }
      if (!this.form.code) {
        return alert('请输入验证码！')
      }
      const body = {
        phone_mob: this.form.phone,
        captcha: this.form.code
      }
      // console.log(body)
      try {
        const { data } = await doLogin(body)
        setUserInfo(data)
        this.$emit('update-status')
        alert('登录成功！')
        this.close(true)
      } catch (e) {
        console.log(e)
        if (e.message === '验证码错误') {
          this.pool.push('code-fail')
        } else {
          alert(e.message)
        }
      }
    },
    close (v) {
      if (v) {
        this.form.deviceType = ''
        this.form.phone = ''
        this.form.code = ''
        if (this.timer) {
          clearInterval(this.timer)
          this.timer = null
        }
        this.countDown = 0
      }
      this.pool.pop()
    }
  }
}
</script>

<style>

</style>
