<template>
  <div id="news">
    <div class="nav">
      <div v-if="isDesktop" class="left">
        <span class="title">
          新闻公告
        </span>
        <span class="sub-title">
          NEWS
        </span>
        <br>
        <span class="breadcrumb">
          当前位置：首页 > 攻略指南
        </span>
      </div>
      <div class="category">
        <span
          v-for="item in categorys"
          :class="{
            active: item.value === activeCategorys
          }"
          :key="item.value"
          @click="changeCategory(item.value)"
          >
          {{item.label}}
        </span>
      </div>
    </div>
    <div class="line" />
    <div class="list-container">
      <div
        v-for="item in list"
        :key="item.id"
        class="row">
        <div class="box">
          <div class="type">{{ item.cate_name }}</div>
          <div class="news">
            <p class="title"  @click="go(item.id)">
              {{item.title}}
            </p>
            <p v-if="isDesktop" class="preview" v-html="item.content">

            </p>
          </div>
        </div>
        <div class="operation">
          <div v-if="isDesktop" class="date">
            <div class="day">{{parseTime(item.add_time + '000','{m}-{d}')}}</div>
            <div class="year">{{parseTime(item.add_time + '000','{y}')}}</div>
          </div>
          <div v-if="isDesktop" class="go" @click="go(item.id)"></div>
          <div v-if="!isDesktop" class="date">
            {{parseTime(item.add_time + '000','{y}-{m}-{d}')}}
          </div>
        </div>
      </div>
    </div>
    <div id="page">
      <div class="pagination">
        <button
          v-if="page.current > 1"
          @click="page.current--"
          class="pre">上一页</button>
        <template v-if="isDesktop">
          <span
            class="num"
            :class="{
              active: item === page.current
            }"
            v-for="item in pager"
            @click="page.current = item"
            :key="item">{{item}}
          </span>
        </template>
        <button
          v-if="page.current < totalPage"
          @click="page.current++"
          class="next">下一页</button>
      </div>
      <span v-if="isDesktop" class="tip">
        第{{page.current}}页 共{{totalPage}}页
      </span>
    </div>
  </div>
</template>

<script>
import {
  // getProcess,
  getArticleCategory,
  getArticleList
} from '@/api'
import { parseTime } from '@/utils'
export default {
  name: 'News',
  created () {
    require(`../styles/${this.targetFolder}/news.scss`)
  },
  computed: {
    activeCategorys () {
      return this.$route.params.type
    },
    totalPage () {
      const total = Math.ceil(this.page.total / this.page.size)
      // eslint-disable-next-line no-self-compare
      return total === total ? total : 1
    },
    targetFolder () {
      return this.$store.state.targetFolder
    },
    isDesktop () {
      return this.$store.state.isDesktop
    },
    pager () {
      const current = this.page.current
      const arr = [current]
      const totalPage = this.totalPage
      if (totalPage < 7) {
        const arr = []
        for (let index = 1; index <= totalPage; index++) {
          arr.push(index)
        }
        return arr
      }
      let f = false
      if (current + 3 > totalPage) {
        f = true
        if (current + 1 <= totalPage) {
          arr.push(current + 1)
        }
        if (current + 2 <= totalPage) {
          arr.push(current + 2)
        }
        if (current + 3 <= totalPage) {
          arr.push(current + 3)
        }
      } else {
        if (current - 1 > 0) {
          arr.unshift(current - 1)
        }
        if (current - 2 > 0) {
          arr.unshift(current - 2)
        }
        if (current - 3 > 0) {
          arr.unshift(current - 3)
        }
      }
      const loop = 7 - arr.length
      for (let index = 1; index <= loop; index++) {
        if (f) {
          arr.unshift(current - index)
        } else {
          arr.push(current + index)
        }
      }
      return arr
    }
  },
  watch: {
    'page.current' (v) {
      this.getArticList()
    },
    activeCategorys () {
      this.page.current = 1
      this.getArticList()
    }
  },
  data () {
    return {
      page: {
        total: 100,
        current: 1,
        size: window.device.type === 'desktop' ? 5 : 20
      },
      // activeCategorys: this.$route.params.type,
      list: Array(5).fill({
        type: '新闻',
        title: '这是一个新闻标题'.repeat(10),
        preview: '这是一个预览内容'.repeat(100),
        year: '2020',
        date: '10-11'
      }),
      categorys: [
        {
          label: '最新',
          value: '1'
        },
        {
          label: '新闻',
          value: '2'
        },
        {
          label: '公告',
          value: '3'
        },
        {
          label: '活动',
          value: '4'
        }
      ]
    }
  },
  mounted () {
    // console.log('news mounted')
    // document.body.scrollIntoView()
    this.getArticleCategory()
  },
  activated () {
    this.$nextTick(() => {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    })
  },

  methods: {
    parseTime,
    go (index) {
      this.$router.push(`/news/${this.activeCategorys}/${index}`)
    },
    async getArticleCategory () {
      try {
        const sort = ['公告', '活动', '新手攻略', '进阶攻略', '公益']
        const { data } = await getArticleCategory()
        this.articleCategory = []
        let temp = []
        sort.forEach(v => {
          const target = data.find(t => t.cate_name === v)
          if (target) {
            temp.push({
              label: target.cate_name,
              value: target.cate_id
            })
          }
        })
        temp = [...temp]
        temp.unshift({
          label: '最新',
          value: 'newest'
        })
        this.categorys = temp
        this.getArticList()
      } catch (e) {
        console.log(e)
        alert(e.message)
      }
    },
    changeCategory (v) {
      // this.activeCategorys = v
      // this.page.current = 1
      // this.getArticList()
      if (this.$route.params.type === v) return
      this.$router.push('/news/' + v)
    },
    async getArticList () {
      try {
        const value = this.categorys.find(v => v.value === this.activeCategorys)
        if (!value) {
          this.$router.push('/news/newest')
        }
        const params = {
          cate_id: this.activeCategorys,
          limitsize: this.page.size,
          offset: (this.page.current - 1) * this.page.size
        }
        if (this.activeCategorys === 'newest') {
          params.cate_id = undefined
        }
        const { data } = await getArticleList(params)
        // console.log(data.result)
        this.list = data.result
        this.page.total = Number(data.rows)
      } catch (e) {
        alert(e.message)
      } finally {
        document.body.scrollIntoView()
        this.$nextTick(() => {
          document.body.scrollTop = document.documentElement.scrollTop = 0
        })
      }
    }
  }
}
</script>

<style>

</style>
