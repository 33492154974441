const tokenKey = 'auth'

export function getUserInfo () {
  try {
    return JSON.parse(localStorage.getItem(tokenKey))
  } catch (e) {
    return null
  }
}

export function setUserInfo (json) {
  localStorage.setItem(tokenKey, JSON.stringify(json))
}

export function removeUserInfo () {
  localStorage.removeItem(tokenKey)
}
