import axios from 'axios'
// import { getUserInfo } from '@/utils/store'
// create an axios instance
const service = axios.create({
  baseURL: 'https://m.gm88.com', // url = base url + request url
  // baseURL: 'https://demo.gm88.com/', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    // if (getUserInfo()) {
    //   // let each request carry token
    //   // ['X-Token'] is a custom headers key
    //   // please modify it according to the actual situation
    //   config.headers['X-Token'] = getUserInfo().token
    // }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (!res.status) {
      console.log(res)
      return Promise.reject(new Error(res.errortext))
    } else {
      return res
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
