<template>
  <div id="details">
    <div class="top">
      <div class="title" :title="info.title">{{info.title}}</div>
      <div class="date">{{parseTime(info.post_time + '000', '{y}-{m}-{d}')}}</div>
      <div v-if="isDesktop" class="goback" @click="goBack" />
      <div v-if="isDesktop" class="breadcrumb">
          当前位置：首页 > 攻略指南 > 正文
      </div>
      <div class="line" />
    </div>
    <div class="txt" v-html="info.content">
    </div>
  </div>
</template>

<script>
import { getArticleDetails } from '@/api'
import { parseTime } from '@/utils'
export default {
  name: 'Details',
  created () {
    require(`../styles/${this.targetFolder}/details.scss`)
  },
  computed: {
    targetFolder () {
      return this.$store.state.targetFolder
    },
    isDesktop () {
      return this.$store.state.isDesktop
    }
  },
  mounted () {
    // console.log('details 123')
    // document.body.scrollIntoView()
    // this.$nextTick(() => {
    //   document.body.scrollTop = document.documentElement.scrollTop = 0
    // })
    // this.getInfo()
  },
  activated () {
    this.$nextTick(() => {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    })
    this.getInfo()
  },
  data () {
    return {
      info: {
        content: '',
        post_time: '',
        id: '',
        title: ''
      }
    }
  },
  methods: {
    parseTime,
    goBack () {
      this.$router.push('/news/' + this.$route.params.type)
    },
    async getInfo () {
      try {
        const { data } = await getArticleDetails({
          id: this.$route.params.id
        })
        this.info = data
      } catch (e) {
        alert(e.message)
      }
    }
  }
}
</script>

<style>

</style>
