<template>
  <div v-if="show" class="video-wall" @click="show = false">
    <videoPlayer
      ref="videoPlayer"
      @click.native.stop
      class="video"
      :style="{
        width: isDesktop ? '50vw' : '100vw'
      }"
      :options="playerOptions"
      @onError="onError"
    />
  </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
export default {
  components: { videoPlayer },
  name: 'Video',
  data () {
    return {
      show: false
    }
  },
  methods: {
    onError () {
      console.log('视频报错了')
    },
    doShow () {
      // if()
      // this.$refs.video.load()
      this.show = true
    }
  },
  computed: {
    playerOptions () {
      return {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        controls: true,
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4',
            src: '/video.mp4'
          }
        ],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请返回下载。' // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        // controlBar: {
        //   timeDivider: true, // 当前时间和持续时间的分隔符
        //   durationDisplay: true, // 显示持续时间
        //   remainingTimeDisplay: false, // 是否显示剩余时间功能
        //   fullscreenToggle: true // 是否显示全屏按钮
        // }
      }
    },
    isDesktop () {
      return this.$store.state.isDesktop
    }
  }

}
</script>

<style lang="scss">
.video-wall {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: .6);
  z-index: 2;
  .video {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>
