import request from '@/utils/request'
function buildParams (action, params = {}) {
  return {
    ...params,
    website_id: 8,
    action
  }
}
function buildData (data = {}) {
  const fd = new FormData()
  for (const key in data) {
    fd.append(key, data[key])
  }
  return fd
}

export function doLogin (data) {
  return request({
    url: 'gateway/',
    method: 'post',
    params: buildParams('qmxb.login'),
    data: buildData(data)
  })
}
// user_info
export function getInfo (params) {
  return request({
    url: 'gateway/',
    method: 'get',
    params: buildParams('ysjdzz.user_info', params)
  })
}

export function doReservation (data) {
  return request({
    url: 'gateway/',
    method: 'post',
    params: buildParams('qmxb.appointment'),
    data: buildData(data)
  })
}

export function logout (data) {
  return request({
    url: 'gateway/',
    method: 'post',
    params: buildParams('user.logout'),
    data
  })
}

export function sendCaptcha (data) {
  return request({
    url: 'gateway/',
    method: 'post',
    params: buildParams('qmxb.send_captcha'),
    data: buildData(data)
  })
}

export function getProcess () {
  return request({
    url: 'gateway/',
    method: 'get',
    params: buildParams('qmxb.appointmentProgress')
  })
}

export function getArticleCategory () {
  return request({
    url: 'gateway/',
    method: 'get',
    params: buildParams('qmxb.news_cate')
  })
}

export function getArticleList (params) {
  return request({
    url: 'gateway/',
    method: 'get',
    params: buildParams('qmxb.news_list', params)
  })
}

export function getArticleDetails (params) {
  return request({
    url: 'gateway/',
    method: 'get',
    params: buildParams('ysjdzz.news_info', params)
  })
}
