<template>
  <div class="not_allow">
    <div class="text">
      <img src="../assets/redirect/arrow.png" class="arrow">
      <div>下载就差一步啦！</div>
      <div>请点击<span class="red">右上角...</span></div>
      <div>选择<span class="red">“在浏览器中打开”</span></div>
    </div>
    <img src="../assets/redirect/demo.png" class="demo">
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@function px( $px ){
  @return $px/$designWidth*10 + rem;
}

$designWidth : 750;
.not_allow {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f8f8f8;
  width: 100vw;
  height: 100vh;
  img.demo {
    width: px(699);
    height: px(699);
    position: absolute;
    bottom: px(175);
    left: 50%;
    transform: translateX(-50%);
  }
  .text {
    position: absolute;
    bottom: px(175 + 699 + 100);
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-size: px(36);
    font-weight: bold;
    color: #3f3f3e;
    img.arrow {
      position: absolute;
      right: 0;
      top: 0;
      width: px(153);
      height: px(104);
      transform: translate(px(75),px(-90));
    }
    > div {
      margin-top: px(10);
    }
    .red {
      color: #db1818;
    }
  }
}
</style>
